<template>
  <b10-base>
    <b10-toolbar :title="title">
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <lparte-trabajo-material-afectado-desinstalar-form
          :id="routeParams.idparte_trabajo_matsist"
          :has-perm-borrar-material-instalado="hasViewPerm(permissions.sistemas.borrarMaterialInstalado)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteTrabajoMaterialAfectadoDesinstalarForm from './components/LparteTrabajoMaterialAfectadoDesinstalarForm'
import Data from './LparteTrabajoMaterialAfectadoDesinstalarData'
import { get } from 'vuex-pathify'
import { TIPO_QUITAR_MATERIAL_AFECTADO_PARTE } from '@/utils/consts'

export default {
  components: {
    LparteTrabajoMaterialAfectadoDesinstalarForm
  },
  mixins: [formPageMixin],
  data() {
    return {
      parteTrabajoMatsist: {},
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData(formData) {
      this.formData = formData
    },
    async loadPage() {
      this.title = 'Quitar material'
      this.parteTrabajoMatsist = await Data.rowParteTrabajoMatsist(
        this,
        this.routeParams.idparte_trabajo_matsist
      )
    },
    async submitForm() {
      await this.$offline.parteTrabajoMatsist.deleteSync(this.routeParams.idparte_trabajo_matsist)
      if (
        this.formData.tipo_quitar === TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOT.id ||
        this.formData.tipo_quitar === TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOTSistema.id
      ) {
        await this.$offline.ordenTrabajoMatsist.deleteSync(
          this.parteTrabajoMatsist.orden_trabajo_matsist.idorden_trabajo_matsist,
          this.routeParams.idparte_trabajo
        )
      }
      if (this.formData.tipo_quitar === TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOTSistema.id) {
        await this.$offline.materialSistema.updateSync({
          idmaterial_sistema: this.parteTrabajoMatsist.material_sistema.idmaterial_sistema,
          estado: 0,
        })
      }
      await this.$dirty.deleted(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.routeParams.idparte_trabajo_matsist)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.parteTrabajoMatsist.material_sistema.idsubsis)
      // volver a la lista de material afectado del subsistema
      // porque el material del parte se eliminó
      this.$appRouter.replace({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
        query: {
          idsubsis: this.parteTrabajoMatsist.material_sistema.idsubsis,
        },
      })
    },
  },
}
</script>
